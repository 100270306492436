/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import Head from 'next/head' // NPM: Next.js header utility.
import Dynamic from 'next/dynamic' // NPM: Next.js dynamic component utility.
import _ from 'underscore' // NPM: Underscore utility library.


/*
 * PACKAGES
 */
import GradientStripe from '~packages/lib/Gradient'


/*
 * DATA
 */
import data from '../data.json'


/*
 * COMPONENTS
 */
const Navbar = Dynamic(() => import('~packages/common/container/Navbar'), { 'loading': Object.Next.Dynamic.Loading })
const Footer = Dynamic(() => import('~packages/common/container/Footer'), { 'loading': Object.Next.Dynamic.Loading })
const Banner = Dynamic(() => import('~packages/common/container/Banner'), { 'loading': Object.Next.Dynamic.Loading })
const ProjectList = Dynamic(() => import('~packages/common/container/ProjectList'), { 'loading': Object.Next.Dynamic.Loading })
const Clients = Dynamic(() => import('~packages/common/container/Clients'), { 'loading': Object.Next.Dynamic.Loading })
const Services = Dynamic(() => import('~packages/common/container/Services'), { 'loading': Object.Next.Dynamic.Loading })
const Radices = Dynamic(() => import('~packages/common/container/Radices'), { 'loading': Object.Next.Dynamic.Loading })
const OurFounder = Dynamic(() => import('~packages/common/container/OurFounder'), { 'loading': Object.Next.Dynamic.Loading })
const GetInTouch = Dynamic(() => import('~packages/common/container/GetInTouch'), { 'loading': Object.Next.Dynamic.Loading })
const GlobalFooter = Dynamic(() => import('~packages/common/container/GlobalFooter'), { 'loading': Object.Next.Dynamic.Loading })


/*
 * OBJECTS
 */
const Index = ({ container }) => {
  // Hook assignment.
  const [activeFilter, setActiveFilter] = React.useState('ALL')
  const [filter, setFilter] = React.useState([])
  const [projectData, setProjectData] = React.useState([])
  const [clients, setClients] = React.useState([])

  // Event handler.
  React.useEffect(() => {
    // Initialize gradient.
    new GradientStripe().initGradient('#BannerGradient')
    new GradientStripe({ 'seed': 93 }).initGradient('#RadicesGradient')
  })
  React.useEffect(() => {
    // Local variable.
    let _uniqueProjectTypes, i

    // Variable assignment.
    _uniqueProjectTypes = []

    // Const assignment.
    const _filterCounts = {}
    const _filterArray = []

    /*
     * Loop over available project types
     * and filter them.
     */
    for (const [__name, __data] of Object.entries(data)) {
      /*
       * Push given project type and make them
       * uppercase.
       */
      _uniqueProjectTypes.push(...__data.type)

      // Uppercase all the filters name.
      _uniqueProjectTypes.map(k => __name.toUpperCase())

      // Remove duplicates from given array.
      _uniqueProjectTypes = _.compact(_.unique(_uniqueProjectTypes))
    }

    // Filter our unique project types.
    for (i = 0 ;i < _uniqueProjectTypes.length ;i++) {
      // Const assignment.
      const _type = _uniqueProjectTypes[i]

      /*
       * Condition if any project type repeated
       * then increment it.
       */
      if (_filterCounts[_type]) {
        // Filter count.
        _filterCounts[_type]++
      } else {
        // Initialize the filter count.
        _filterCounts[_type] = 1
      }
    }

    // Loop over word count and update filter array.
    for (const [__word, __count] of Object.entries(_filterCounts)) {
      // Push entry to filter array.
      _filterArray.push({
        'projectDone': __count,
        'heading': __word
      })
    }

    // Generate project list for given data.
    const _dataArray = Object.entries(data).map(u => ({
      'id': u[1].id,
      'image': u[1].splashScreen,
      'title': u[1].name,
      'fields': u[1].deliverables,
      'filter': u[1].type,
      'slug': u[1].id,
      'slogan': u[1].slogan,
      'logo': u[1].logo,
      'background':u[1]?.backgroundImage
    }))
    const _clients = Object.entries(data).map(j => ({
      'id': String.random(8),
      'image': j[1].logo,
      'title': j[1].name,
      'description': j[1].overviewHeading,
      'background':j[1]?.backgroundImage
    }))

    // Update state with given filter.
    !_.isEmpty(_filterArray) && _.isArray(_filterArray) && setFilter(_filterArray)
    !_.isEmpty(_dataArray) && _.isArray(_dataArray) && setProjectData(_dataArray)
    !_.isEmpty(_clients) && _.isArray(_clients) && setClients(_clients)
  }, [])

  // Return component.
  return (
    <section style={container}>
      <Head>
        <title>Rootandleaves | A Leader in Software Development</title>
        <meta name='Description' content='Rootandleaves is a leader in software development, with a focus on innovative and beautiful solutions in JavaScript, Python and Rust languages. We provide IT support and digital marketing services to businesses in Bangalore and Gurgaon.' />
      </Head>
      <section className='gradientWrapper topLeft round'>
        <canvas id='BannerGradient' className='gradient color theme' />
        <div className='overlay occlusion' />
        <Navbar/>
        <Banner/>
      </section>
      {/* <ProjectList filter={filter} data={projectData} activeFilterType={activeFilter} setActiveFilterType={setActiveFilter} showFilter /> */}
      <Clients data={clients} />
      <OurFounder />
      <Services />
      <section className='gradientWrapper topRight round'>
        <canvas id='RadicesGradient' className='gradient color theme' />
        <div className='overlay occlusion' />
        <Radices />
      </section>
      <GetInTouch />
      <Footer />
      <GlobalFooter />
    </section>
  )
}



/*
 * PROPTYPES
 */
Index.defaultProps = {
  'container': {
    'overflowX': 'hidden'
  }
}


/*
 * EXPORTS
 */
export default Index
